import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [

  { path: "/",         component: () => import("../views/Sources.vue")  },
  { path: "/accounts", component: () => import("../views/Accounts.vue") },

  { path: "/filter",   component: () => import("../views/Filter.vue")   },
  { path: "/itr",      component: () => import("../views/Itrs.vue")     },
  { path: "/incomes",  component: () => import("../views/Heads.vue"), props: { type: "Incomes"  }  },
  { path: "/expenses", component: () => import("../views/Heads.vue"), props: { type: "Expenses" }  },
  { path: "/taxes",    component: () => import("../views/Heads.vue"), props: { type: "Taxes"    }  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
