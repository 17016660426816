<template>
  <v-app>
    
    <v-app-bar app hide-elevate-on-scroll hide-on-scroll dense color="primary" dark>

      <div class="d-flex align-center">
        <v-img
          alt="Paisa Logo"
          class="shrink mr-2"
          contain
          src="/images/wallet-512.png"
          transition="scale-transition"
          width="40"
        />
        <h1>Paisa</h1>
      </div>

      <v-btn depressed dark color="primary" class="ml-2" style="text-transform: unset;" to="/">Sources</v-btn>
      <v-btn depressed dark color="primary" class="ml-2" style="text-transform: unset;" to="/accounts">Accounts</v-btn>

      <v-btn depressed dark color="primary" class="ml-2" style="text-transform: unset;" to="/filter">Filter</v-btn>
      <v-btn depressed dark color="primary" class="ml-2" style="text-transform: unset;" to="/itr">ITR</v-btn>
      <v-btn depressed dark color="primary" class="ml-2" style="text-transform: unset;" to="/incomes">Incomes</v-btn>
      <v-btn depressed dark color="primary" class="ml-2" style="text-transform: unset;" to="/expenses">Expenses</v-btn>
      <v-btn depressed dark color="primary" class="ml-2" style="text-transform: unset;" to="/taxes">Taxes</v-btn>

      <v-spacer></v-spacer>

      <div ref="GoogleLoginButton" class="ml-2" style="display:none;"></div>

    </v-app-bar>

    <v-main class="grey">
      <v-container fluid>
        <router-view :doGet="doGet" :doPost="doPost" />
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
import axios from "axios";
export default {
  name: "App",
  data: () => ({
    location: undefined,
    session: undefined,
  }),
  async mounted() {

    navigator.geolocation.watchPosition(position => {
      this.location = {
        coords: {
          latitude : position.coords.latitude,
          longitude: position.coords.longitude,
          accuracy : position.coords.accuracy
        },
        timestamp: position.timestamp
      }
    });   

    for(let cookie of document.cookie.split(';')) {
      cookie = cookie.trim();
      if(cookie.startsWith('sessionId=') && cookie != 'sessionId=') {
        this.session = { id: cookie.substring('sessionId='.length) };
        break;
      }
    }

    if(this.session) {
      this.session = await this.doGet('/api/user/session');
    } else {
      this.session = await this.doPost('/api/user/session', { userAgent: navigator.userAgent });
      document.cookie = `sessionId=${ this.session.id }; path=/;`;
    }

    if(this.session.status == 'active') {
      this.$refs['GoogleLoginButton'].style.display = '';
      window.google.accounts.id.initialize({
        client_id: '220251834863-p6gimkv0cgepodik4c1s8cs471dv9ioq.apps.googleusercontent.com',
        callback: async (resp) => {
          await this.doPost('/api/user/google-login', { idToken: resp.credential });
          window.location.reload();
        },
        auto_select: true
      });
      window.google.accounts.id.renderButton(this.$refs['GoogleLoginButton'], {});
      window.google.accounts.id.prompt();
    } else if(this.session.status == 'loggedin') {
      setInterval(() => {
        this.doPost('/api/user/session/ping', { userAgent: navigator.userAgent, location: this.location });
      }, 60 * 1000);
    }

  },
  methods: {
    async doRequest(req) {
      let resp = await axios({ ...req, validateStatus: status => status == 200 || status == 401 });
      if(resp.status == 401) {
        document.cookie = 'sessionId=; path=/;';
        window.location.reload();
      }
      return resp.data;
    },
    async doGet(api, query = {}) {
      return await this.doRequest({ method: 'get', url: api, params: query });
    },
    async doPost(api, body = {}) {
      return await this.doRequest({ method: 'post', url: api, data: body });
    },
  },
};
</script>
